<template>
  <PageBody>
    <MainSwiper />
    <HomeMainLastTopup />
    <!-- Ниже страница последних покупок. Закрыто до появления бэка -->
    <!-- <HomeMainLastPurchases /> -->
    <MainGames />
  </PageBody>
</template>

<script setup lang="ts">
import MainSwiper from "~/components/Home/MainSwiper.vue";
import MainGames from "~/components/Home/MainGames.vue";

const route = useRoute();

if (isNaN(Number(route.params.page))) {
  showError({ statusCode: 404 });
}

const { t } = useI18n();
useSeoMeta({
  title: t("home.title"),
  description: t("home.description"),
  keywords: t("home.keywords"),
});
</script>
